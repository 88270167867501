import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"

import Colors from "../components/vars/colors"
import Breakpoints from "../components/vars/breakpoints"
import ArrowLeftIcon from "../images/svg-icons/long-arrow-alt-left-solid.svg"
import ArrowRightIcon from "../images/svg-icons/long-arrow-alt-right-solid.svg"
import PlayIcon from "../images/svg-icons/play-solid.svg"
import PauseIcon from "../images/svg-icons/pause-solid.svg"
import VolumeIcon from "../images/svg-icons/volume-up-solid.svg"

const StyledAudioPlayer = styled.div`
display: flex;
align-items: center;
justify-content: center;
background: #fff;
padding: 1.25rem 1.6rem 1.25rem 1.25rem;
border-radius: 0 0 5px 5px;

.forwardBackward {
    background: none;
    border: none;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
    svg {
        height: 1rem;
    }
    svg:first-child { margin-right: 0.25rem; }
    svg:nth-child(1) { margin-left: 0.25rem; }
    &:hover {
        color: teal;
    }
}

.playPause {
    display: block;
    background: ${ Colors.primary.light };
    border: none;
    border-radius: 50%;
    flex-shrink: 0;
    width: 65px;
    height: 65px;
    font-size: 2rem;
    color: ${ Colors.text.primary };
    cursor: pointer;
    svg {
        height: 21px;
        position: relative;
        top: -1px;
    }
}

.volumeButton {
    display: block;
    padding: 0 5px;
    margin: 0 0.2rem 0 2rem;
    background: none;
    border: none;
    color: ${ Colors.text.primary };
    height: 17px;
    svg {
        height: 100%;
    }
}

.play {
    left: 2px;
}

.currentTime,
.duration {
    font-size: 15px;
}

.progressContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: 1.5rem;
    flex: 1;
}
.timestampContainer {
    display: flex;
    justify-content: space-between;
    position: absolute; // relative to .progressContainer
    width: 100%;
    margin-top: 1.25rem;
}
.playPauseContainer {
    display: flex;
}
.volumeContainer {
    display: flex;
    align-items: center;
}

/* Styling differences for progressBar and volumeBar */
.progressBar {
    --bar-height: 5px;
    --bar-bg: ${Colors.primary.main}99; // 99 = hex value for 60% opacity
    --seek-before-width: 0;
    --seek-before-color: ${ Colors.primary.dark };
    --playhead: ${ Colors.primary.dark };
    --selectedPlayhead: ${ Colors.secondary.dark };
}
.volumeBar {
    --bar-height: 5px;
    --bar-bg: ${ Colors.secondary.light }99; // 99 = hex value for 60% opacity: ;
    --seek-before-width: 75%;
    --seek-before-color: ${ Colors.secondary.dark };
    --playhead: ${ Colors.secondary.dark };
    --selectedPlayhead: ${ Colors.secondary.light };
    width: 75px;
}

.progressBar, .volumeBar {
    appearance: none;
    border-radius: 0.5rem;
    position: relative;
    outline: none;
    height: var(--bar-height);
    background: var(--bar-bg);
}

// Progress bar - safari
.progressBar::-webkit-slider-runnable-track, .volumeBar::-webkit-slider-runnable-track {
    border-radius: 50%;
    position: relative;
    width: 100%;
    height: var(--bar-height);
    outline: none;
}
.progressBar::-webkit-slider-runnable-track {
    background: var(--bar-bg);
}
.volumeBar::-webkit-slider-runnable-track {
    background: var(--bar-bg);
}


//Progress bar - firefox
.progressBar::-moz-range-track, .volumeBar::-moz-range-track {
    border-radius: 50%;
    position: relative;
    width: 100%;
    height: var(--bar-height);
    outline: none;
}

.progressBar::-moz-focus-outer, .volumeBar::-moz-focus-outer {
    outline: none;
}

// Progress bar - chrome and safari
.progressBar::before, .volumeBar::before {
    content: '';
    height: var(--bar-height);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
}
.progressBar::before {
    width: var(--seek-before-width);
    background-color: var(--seek-before-color);
}
.volumeBar::before {
    width: var(--seek-before-width);
    background-color: var(--seek-before-color);
}

// Progress bar - firefox
.progressBar::-moz-range-progress, .volumeBar::-moz-range-progress {
    height: var(--bar-height);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.progressBar::-moz-range-progress {
    background-color: var(--seek-before-color);
}
.volumeBar::-moz-range-progress {
    background-color: var(--seek-before-color);
}


// Playhead - chrome
.progressBar::-webkit-slider-thumb, .volumeBar::-webkit-slider-thumb {
    -webkit-appearance: none;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    position: relative;
    z-index: 3;
    box-sizing: border-box;
}
.progressBar::-webkit-slider-thumb {
    background-color: var(--playhead);
    margin: -8px 0;
    height: 21px;
    width: 21px;
}
.volumeBar::-webkit-slider-thumb {
    background-color: var(--playhead);
    margin: -6px 0;
    height: 17px;
    width: 17px;
}

// Playhead while dragging - chrome and safari
.progressBar:active::-webkit-slider-thumb, .volumeBar:active::-webkit-slider-thumb {
    transform: scale()(1.2);
}
.progressBar:active::-webkit-slider-thumb {
    background: var(--selectedPlayhead);
}
.volumeBar:active::-webkit-slider-thumb {
    background: var(--selectedPlayhead);
}

// Playhead - firefox
.progressBar::-moz-range-thumb, .volumeBar::-moz-range-thumb {
    border-radius: 50%;
    border: transparent;
    cursor: pointer;
    position: relative;
    z-index: 3;
    box-sizing: border-box;
}
.progressBar::-moz-range-thumb {
    background-color: var(--playhead);
    height: 21px;
    width: 21px;
}
.volumeBar::-moz-range-thumb {
    background-color: var(--playhead);
    height: 17px;
    width: 17px;
}

// Playhead while dragging - firefox
.progressBar:active::-moz-range-thumb, .volumeBar:active::-moz-range-thumb {
    transform: scale()(1.2);
}
.progressBar:active::-moz-range-thumb {
    background: var(--selectedPlayhead);
}
.volumeBar:active::-moz-range-thumb {
    background: var(--selectedPlayhead);
}

// Small screens
@media only screen and (max-width: ${Breakpoints.sizes.small}) {
    flex-direction: column;
    gap: 2rem;

    .progressContainer {
        margin: auto;
    }
    .progressBar {
        min-width: 18rem;
    }

    .volumeContainer {
        margin-top: 1rem;
        min-width: 20rem;
        position: relative;
        justify-content: center;
        .volumeButton {
            margin: 0;
            position: absolute;
            left: 86px;
        }
    }
}

`


const AudioPlayer = (props) => {
    // State
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [volume, setCurrentVolume] = useState(100);

    // References
    const audioPlayer = useRef(); // reference to audio component
    const progressBar = useRef(); // reference to progressBar
    const animationRef = useRef(); // reference to animation
    const volumeBar = useRef(); // reference to volume

    useEffect(() => {
        const seconds = Math.floor(audioPlayer.current.duration);
        setDuration(seconds);
        progressBar.current.max = seconds;
    }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState]);

    useEffect(() => {
        setCurrentVolume(volume);
    }, []);

    const calculateTime = (secs) => {
        const minutes = Math.floor(secs / 60);
        const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

        return `${returnedMinutes}:${returnedSeconds}`;
    }

    const togglePlayPause = () => {
        const prevValue = isPlaying;
        setIsPlaying(!prevValue);
        if(!prevValue) {
            audioPlayer.current.play();
            animationRef.current = requestAnimationFrame(whilePlaying);
        } else {
            audioPlayer.current.pause();
            cancelAnimationFrame(animationRef.current);
        }
    }

    const whilePlaying = () => { 
        progressBar.current.value = audioPlayer.current.currentTime;
        changePlayerCurrentTime()
        animationRef.current = requestAnimationFrame(whilePlaying);
    }

    const changeRange = () => {
        audioPlayer.current.currentTime = progressBar.current.value;
        changePlayerCurrentTime()
    }

    const changePlayerCurrentTime = () => {
        progressBar.current.style.setProperty("--seek-before-width", `${progressBar.current.value / duration * 100}%`)
        setCurrentTime(progressBar.current.value);
    }

    const backTwenty = () => {
        progressBar.current.value = Number(progressBar.current.value - 20);
        changeRange();
    }

    const forwardTwenty = () => {
        progressBar.current.value = Number(progressBar.current.value) + 20;
        changeRange();
    }

    const changeVolume = () => {
        audioPlayer.current.volume = volumeBar.current.value / 100;
        changeVolumeCurrentNumber()
    }

    const changeVolumeCurrentNumber = () => {
        volumeBar.current.style.setProperty("--seek-before-width", `${volumeBar.current.value}%`);
        setCurrentVolume(volumeBar.current.value);
    }

    return (
        <StyledAudioPlayer>
            <audio ref={ audioPlayer } src={ props.audioSource } type="audio/mpeg">
                <track default kind="captions" srcLang="en" />
            </audio>
            <div className="playPauseContainer">
                <button className="forwardBackward" onClick={ backTwenty }><ArrowLeftIcon />20</button>
                <button onClick={togglePlayPause} className="playPause">
                    {isPlaying ? <PauseIcon /> : <PlayIcon className="play"/> }
                </button>
                <button className="forwardBackward" onClick={ forwardTwenty }>20<ArrowRightIcon /></button>
            </div>
            <div className="progressContainer">
                {/* progress bar */}
                <input type="range" className="progressBar" defaultValue="0" ref={ progressBar } onChange={ changeRange }/>
                <div className="timestampContainer">
                    {/* curent time */}
                    <div className="currentTime">{ calculateTime(currentTime) }</div>
                    {/* duration */}
                    <div className="duration">{(duration && !isNaN(duration)) && calculateTime(duration)}</div>
                </div>
            </div>

            {/* volume control */}
            <div className="volumeContainer">
                <button className="volumeButton">
                    <VolumeIcon className="volumeIcon" />
                </button>
                <input type="range" className="volumeBar" defaultValue="100" max="100" ref={ volumeBar } onChange={ changeVolume } />
            </div>
            
        </StyledAudioPlayer>
    )
}

export default AudioPlayer