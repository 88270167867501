import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const helmet = (props, { data }) => {
    return (
        <StaticQuery
            query={seoQuery}
            render={ data => {
                const metaTitle = props.title || data.site.siteMetadata.title
                const metaDescription = props.description || data.site.siteMetadata.description
                return (
                    <Helmet
                        titleTemplate={ `%s | ${ data.site.siteMetadata.title }` }
                        title={ metaTitle }
                        meta={[
                            {
                                name: `description`,
                                content: metaDescription
                            },
                            {
                                property: `og:title`,
                                content: props.title
                            },
                            {
                                property: `og:description`,
                                content: metaDescription
                            },
                            {
                                property: `og:type`,
                                content: `website`
                            },
                            {
                                name: `twitter:card`,
                                content: `summary`
                            },
                            {
                                name: `twitter:creator`,
                                content: data.site.siteMetadata.author
                            },
                            {
                                name: `keywords`,
                                content: props.keywords || data.site.siteMetadata.keywords
                            }
                        ]}
                    />
                )
             } }
        />
    )
}

export default helmet;

const seoQuery = graphql`
    query SeoQuery {
        site {
            siteMetadata {
                title
                description
                author
                keywords
            }
        }
    }
`