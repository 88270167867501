import React from "react"
import styled from "styled-components"

import Colors from "./vars/colors"
import Breakpoints from "./vars/breakpoints"

import FacebookIcon from "../images/svg-icons/facebook-square-brands.svg"
import TwitterIcon from "../images/svg-icons/twitter-square-brands.svg"
import LinkedinIcon from "../images/svg-icons/linkedin-brands.svg"
import WhatsappIcon from "../images/svg-icons/whatsapp-square-brands.svg"
import EmailIcon from "../images/svg-icons/envelope-square-solid.svg"

const socialcolors = {
    facebook: '#3b5998',
    twitter: '#1da1f2',
    linkedin: '#0a66c2',
    whatsapp: '#25d366',
    email: Colors.primary.dark
}


const StyledCopyPaste = styled.div`
    span {
        display: flex;
    }
    input {
        min-width: 3rem;
        width: 35rem;
        padding: 0.75rem;
        text-overflow: ellipsis;
        border: 0.15rem solid ${ Colors.grey.g7 };
        border-right: 0;
        border-radius: 0.5rem 0 0 0.5rem;
    }
    button {
        cursor: pointer;
        white-space: nowrap;
        background: #fff;
        font-size: 1em;
        border: 0.15rem solid ${ Colors.grey.g7 };
        background: ${ Colors.grey.g7 };
        min-width: 6.75rem;
        border-radius: 0 0.5rem 0.5rem 0;
    }
    input.active {
        border: 0.15rem solid ${ Colors.primary.light };
        border-right: 0;
    }
    button.active {
        background: ${ Colors.primary.light };
        border: 0.15rem solid ${ Colors.primary.light };
    }

    @media only screen and (${ Breakpoints.device.medium }) {
        input {
            width: 20rem;
        }
    }

`
const CopyPaste = (props) => {
    const RemoveClass = () => {
        document.getElementById("CopyPaste").innerHTML = "Copy Link"
    }
    const Copied = () => {
        document.getElementById("CopyPaste").innerHTML = "Copied!"
        setTimeout(() => {RemoveClass()}, 5000);
    }
    const copyTextUrl = () => {
        // Navigator is only on modern browsers
        if(navigator) {
            navigator.clipboard.writeText( props.canonicalUrl );
            Copied()
        } else {
            document.getElementById("canonicalUrl").select();
            document.getElementById("canonicalUrl").setSelectionRange(0, 99999);
            document.execCommand("copy");
            Copied()
        }
    }
    return (
        <StyledCopyPaste>
            <span>
                <input type="text" value={ props.canonicalUrl } id="canonicalUrl" readOnly />
                <button onClick={copyTextUrl} id="CopyPaste">Copy Link</button>
            </span>
        </StyledCopyPaste>
    )
}









const StyledSocialShare = styled.section`

    display: flex;
    flex-direction: column;
    margin: 1rem 0 2rem;

    .socials {
        display: flex;
        gap: 0.75rem;
        margin: 0 auto;
        margin-top: 0.5rem;
    }

    svg {
        height: 2.25rem;
        position: relative;
        top: 0.5rem;
    }


    path{ color: ${ Colors.grey.g5 }; transition: color 0.1s linear; }
    a:hover .facebook path { color: ${ socialcolors.facebook }; }
    a:hover .twitter path { color: ${ socialcolors.twitter } }
    a:hover .linkedin path { color: ${ socialcolors.linkedin } }
    a:hover .whatsapp path { color: ${ socialcolors.whatsapp } }
    a:hover .email path { color: ${ socialcolors.email } }

    @media only screen and (${ Breakpoints.device.medium }) {
        flex-direction: row;
        justify-content: space-between;
        .socials { margin: initial }
    }
`
const BlogMeta = (props) => {
    const title = props.title || ''
    const body = props.body || ''
    const canonicalUrl = props.canonicalUrl || ''

    const encodetitle = encodeURI(title)
    const encodebody = encodeURI(body)
    const encodecanonicalUrl = encodeURI(canonicalUrl)

    let mailtitle, mailbody
    if(title !== '') { mailtitle = 'subject=' + encodetitle }
    if(body !== '') { mailbody = '&body=' + encodebody + " " + encodecanonicalUrl }

    return (
        <StyledSocialShare>
            <CopyPaste canonicalUrl={ encodecanonicalUrl }/>
            <div className="socials">
                <a href={"https://www.facebook.com/sharer/sharer.php?u=" + encodecanonicalUrl + "&t=" + encodetitle} aria-label="Share to Facebook" target="_blank" rel="noreferrer">    
                    <FacebookIcon className='facebook' />
                </a>
                <a href={"https://twitter.com/intent/tweet?text=" + encodetitle + "%20" + encodecanonicalUrl }>
                    <TwitterIcon className='twitter' />
                </a>
                <a href={"https://www.linkedin.com/sharing/share-offsite/?url=" + encodecanonicalUrl }>{/* LinkedIn only supports URL in request */}
                    <LinkedinIcon className='linkedin' />
                </a>
                <a href={ 'mailto:?' + mailtitle + mailbody } aria-label="Email"><EmailIcon className="email" /></a>
                <a href={ "whatsapp://send?text=" + encodecanonicalUrl + "%20" + encodetitle } aria-label="Share to WhatsApp">
                    <WhatsappIcon className="whatsapp" />
                </a>
            </div>
        </StyledSocialShare>
    )
}

export default BlogMeta