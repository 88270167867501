import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

import AudioPlayer from "../components/AudioPlayer"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Nav from "../components/nav/PrimaryNav"
import Footer from "../components/footers/index"
import Gutters from "../components/vars/gutters"
import Container from "../components/containers"
import SocialShare from "../components/socialshare"
import Breakpoints from "../components/vars/breakpoints"
import Colors from "../components/vars/colors"




const StyledBlogHeader = styled.header`
  >div {
    max-width: 48rem;
    margin: 0 auto;
    width: ${ Gutters.medium };
  }
  h1 {
    font-size: 2em;
    margin: 0.5rem 0 1rem 0;
  }
  .postmeta {
    display: block;
    margin-bottom: 2rem;
  }
  img {
    width: 100%;
    max-height: 700px;
    object-fit: cover;
  }
  @media only screen and (${ Breakpoints.device.small }) {
    >div {
      width: ${ Gutters.large };
      h1 {
        font-size: 2.5em;
      }
    }
  }
`
const BlogHeader = (props) => {
  return (
    <StyledBlogHeader>
      <Container.Margin tag="div">
        { props.children }
      </Container.Margin>
    </StyledBlogHeader>
  )
}




const StyledBlogArticle = styled(Container.Margin)`
  width: ${ Gutters.medium };
  max-width: 48rem;
  p {
    font-size: 1.25em;
    margin: 1.5rem 0;
    line-height: 160%;
  }

  ol {
    font-size: 1.25em;
    margin: 1.5rem 0 1.5rem 1.5rem;
    line-height: 160%;
    li { margin-bottom: 0.5rem; }
    li:last-child {
      margin-bottom: unset;
    }
    li::marker {
      font-weight: bold;
    } 
  }

  a {
    color: ${ Colors.secondary.main };
  }

  @media only screen and (${ Breakpoints.device.small }) {
    font-size: 1.15em;
    width: ${ Gutters.large };
  }

`
const BlogArticle = (props) => {
  return (
    <StyledBlogArticle tag="article" content={ props.content } />
  )
}



const StyledBlogNextPrev = styled.div`
  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    li {
      display: block;
      min-height: 2rem;
      div {
        margin-bottom: 2rem;
      }
    }
    a {
      display: inline-block;
      margin: auto;
      line-height: 150%;
      font-size: 1.5em;
      color: ${ Colors.secondary.main };
    }

    @media only screen and (${ Breakpoints.device.small }) {
      a {
        font-size: 1.75em
      }
    }
  }
`
const BlogNextPrev = (props) => {
  return (
    <StyledBlogNextPrev>
      { props.children }
    </StyledBlogNextPrev> 
  )
}




class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const { previous, next } = this.props.pageContext
    console.log(post.frontmatter.audio)
    const audioSource = post.frontmatter.audio;
    
    const canonicalUrl = this.props.data.site.siteMetadata.siteURL + this.props.location.pathname
    // const Blogcasts  = this.props.data.allFeedVizcareerBlogscast.edges

    const image = getImage(post.frontmatter.image) || null

    return (
      <React.Fragment>
          <Seo 
          title={ post.frontmatter.title }
          description={ post.frontmatter.summary || "Some of our thoughts and experiences." }
          keywords={ post.frontmatter.keywords || ['blog', 'video', 'recruitment', 'diversity', `news`]}
          />
          <Nav />
          <BlogHeader>
            <h1>{ post.frontmatter.title }</h1>
            <span className="postmeta">
              Posted by <span rel="author">{ post.frontmatter.author }</span>, on { post.frontmatter.date }
            </span>
            { image != null && 
              <GatsbyImage image={ image } alt="test"/>
            }
            {
              audioSource === "" || audioSource === null ? "" : <AudioPlayer audioSource={ audioSource }/>
            }
          </BlogHeader>
          <Layout>
            <BlogArticle content={{ __html: post.html }}/>
            <Container.Margin width="medium" maxwidth="48rem">
              <BlogNextPrev>
              <SocialShare
                  title={ post.frontmatter.title }
                  body={ post.frontmatter.summary }
                  canonicalUrl={ canonicalUrl }
                />
                <ul>
                  <li>
                    {previous && (
                      <div>
                        <h3>Previous Article</h3>
                        <Link to={"/blog" + previous.fields.slug} rel="prev">
                          {previous.frontmatter.title}
                        </Link>
                      </div>
                    )}
                  </li>
                  <li>
                    {next && (
                      <div>
                        <h3>Next Up</h3>
                        <Link to={"/blog" + next.fields.slug} rel="next">
                          {next.frontmatter.title}
                        </Link>
                      </div>
                    )}
                  </li>
                </ul>
                  </BlogNextPrev>
              </Container.Margin>
        </Layout>
        <Footer />
      </React.Fragment>
    ) 
  }
}

export default BlogPostTemplate

export const blogPageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteURL
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        author
        summary
        date(formatString: "MMMM DD, YYYY")
        keywords
        audio
        image {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              aspectRatio: 1.777
              quality: 80
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
    }
    allFeedVizcareerBlogscastMeta {
      nodes {
        title
        author
        image {
          url
        }
      }
    }
    allFeedVizcareerBlogscast {
      edges {
        node {
          title
          pubDate
          link
          itunes {
            duration
          }
          enclosure {
            url
            length
          }
        }
      }
    }
  }
`
